<template>
    <v-app id="inspire">
        <v-main>
            <v-container class="fill-height" fluid>
                <v-row align="center" justify="center">
                    <v-col cols="12" sm="8" md="4">
                        <v-img src="@/assets/logo.png"></v-img>
                    </v-col>
                </v-row>
                <v-row align="center" justify="center">
                    <v-col cols="12" sm="8" md="4">
                        <v-card class="elevation-12">
                            <v-toolbar color="primary" dark flat>
                                <v-toolbar-title>Sistema Charricos</v-toolbar-title>
                                <v-spacer />
                            </v-toolbar>
                            <v-card-text>
                                <v-form>
                                    <v-text-field v-model="user.email" label="Email" type="email" :error-messages="errors.email" @keyup.enter="login" />
                                    <v-text-field v-model="user.password" label="Password" type="password" :error-messages="errors.password" @keyup.enter="login" />
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer />
                                <v-btn @click="$router.push({name: 'ResetRequest'})">Recuperar contraseña</v-btn>
                                <v-btn color="primary" @click="login" :loading="loading">Ingresar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
    export default {
        props: {
            source: String,
        },
        data: () => ({
            loading: false,
            user: {
                email: null,
                password: null,
            },
            errors: {}
        }), 
        mounted() {
            localStorage.removeItem('token')
        },
        methods: {
            login() {
                this.loading = true
                this.errors = {}
                this.$http.post('/login', this.user).then(response => {
                    this.$http.defaults.headers.common.Authorization = `Bearer ${response.data.token}`
                    localStorage.setItem('token', response.data.token)
                    this.$router.push({ name: 'Dashboard' })
                }).catch(error => {
                    switch (error.response.status) {
                        case 422:
                            this.errors = error.response.data.errors
                            break;

                        default:
                            break;
                    }
                }).finally(() => {
                    this.loading = false
                })
            }
        }
    }
</script>